<template>
  <div class="breadWrap">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        v-for="(breadItem, index) in breadList"
        :key="index"
        >
        <router-link v-if='breadItem.path' :to="{ path: breadItem.path }">{{ breadItem.name }}</router-link>
        <span v-else>{{ breadItem.name }}</span>
        </el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'nfBreadcrumb',
  props: {
    breadList: Array
  }
};
</script>

<style lang="less" scoped>
.breadWrap {
  border: 1px solid #ccc;
  .el-breadcrumb {
    width: 95%;
    margin: 20px auto;
    font-size: 16px;
  }
}
</style>
