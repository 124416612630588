<template>
    <div>
        <!-- 热门实验室 -->
        <div class='contentItem'>
            <div class='title'>热门实验室</div>
            <div v-if='data.length > 0'>
                <router-link tag ='div' :to='{path:"/labShare/labDetaile", query:{id: item.id}}' v-for='(item, index) in data' :key='index' class='clearfix listItem'>
                    <img :src="item.imageUrl" class='fl' alt="">
                    <div class='fl'>
                        <p class='ellipsis'>{{item.name ? item.name : '暂无数据'}}</p>
                        <p class='ellipsis'>{{item.serviceScope ? item.serviceScope : '暂无数据'}}</p>
                        <p class='ellipsis'>{{item.address ? item.address : '暂无数据'}}</p>
                    </div>
                </router-link>
            </div>
            <div v-else class='nodata'> 
                <img :src="require('@/assets/images/littleNoData.png')" alt="">
            </div>
            
        </div>
        <!-- 热门功能推荐 -->
        <div class='contentItem margin20'>
            <div class='title'>热门功能推荐</div>
            <div class='flex_around flex_wrap'>
                <router-link :to="{ path: '/expertTalent' }">人才库</router-link>
                <router-link :to="{ path: '/instrumentShare' }">仪器设备共享</router-link>
                <router-link :to="{ path: '/plantQuarantine' }">植物检疫</router-link>
                <a href='http://218.77.183.220:8080/zgnf/zgnf/admin/login.do?returnUrl=/zgnf/admin/index.do' target="blink">信息报送</a>
                <!-- <router-link :to="{ path: '/subsidyDeclare' }">补贴申请</router-link> -->
                <!-- <router-link :to="{ path: '/employmentService' }" >用工服务</router-link> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            data: []
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        async initData() {
            const api = this.$fetchApi.laboratory.api;
            const param = {
                pageNum: 1,
                pageSize: 3
            };
            const data = await this.$fetchData.fetchPost(param, api, "json");
            if (data.code === '200' && data.result) {
                this.data = data.result.list;
            }
        }
    }
};
</script>
<style lang="less" scoped>
    .margin20{
        margin-top:20px;
    }
    .nodata{
        text-align: center;
        margin:20px 0;
    }
    .contentItem{
        width: 350px;
        border: solid 1px #d9d9d9;
        .title{
            font-size: 16px;
            color: #333;
            font-weight: bold;
            line-height: 30px;
            margin-left:20px;
            margin-bottom: 30px;
        }
        .title::before{
            width: 6px;
            height: 30px;
            background-color: #265ae0;
            display: inline-block;
            content: "";
            position: relative;
            top:8px;
            margin-right:14px;
        }
        .listItem{
            padding: 20px 0;
            margin:0 20px;
            border-bottom: dashed 1px #d9d9d9;
            cursor: pointer;
            &:hover{
                color:#265ae0;
            }
            img{
                width: 70px;
                height: 70px;
                margin-right:10px;
            }
            p{
                width:230px;
            }
        }
        .listItem:last-child{
            border:none;
        }
        
        a{
            width: 120px;
            height: 36px;
            background-color: #fff;
            border-radius: 4px;
            border: solid 1px #448afc;
            line-height: 36px;
            cursor: pointer;
            text-align:center;
            color:#5c5c5c;
            font-size: 16px;
            display: inline-block;
            margin-bottom: 20px;
        }
        a:hover{
            background-color: #448afc;
             color:#fff;
        }
    }
</style>