<template>
  <div class="pagenation">
    <el-pagination
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
      :page-sizes="pageSizes"
      :current-page="currentPage"
      :page-size="pageSize"
      layout=" prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number
    }, // 总条数
    currentPage: {
      type: Number
    },
    pageSize: {
      type: Number
    }
  },
  data() {
    return {
      pageSizes: [10, 20, 50, 100],
      page: {
        page_index: 1,
        page_limit: this.pageSize
      }
    };
  },
  methods: {
    // // 每页查看条数变化
    onSizeChange(val) {
      this.page.page_limit = val;
      this.$emit('pageChange', this.page);
    },
    // 当前页码变化
    onCurrentChange(val) {
      this.page.page_index = val;
      this.$emit('pageChange', this.page);
    }
  }
};
</script>

<style scoped>
.el-pagination {
  text-align: center;
  margin: 40px 0;
}
</style>
