<template>
    <div v-loading="loading">
        <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
        <div class='wrapper'>
            <div class='publicServiceCondition'>
                <!-- <div class="flex publiceCommon cropList" v-if='machines.length > 0'>
                    <div class='title'>机具类别</div>
                    <div class='flex_length flex flex_wrap cropList cropListContent' :class='className'>
                        <div :class='selectCropList==-1?"active":""' @click='onSelectCropList(-1, "")' class='typeName'>全部</div>
                        <div v-for='(itm, idx) in machines' :key='idx+"c"' :class='selectCropList==idx?"active":""'  class='typeName' @click='onSelectCropList(idx, itm.id)' >{{itm.name}}</div>
                    </div>
                    <div class='tip' @click='onTip'>{{tip}}<i :class="className?'el-icon-arrow-up':'el-icon-arrow-down'"></i></div>
                </div> -->
                <el-row class='publiceCommon' >
                    <el-col :span="2" class='title'>机具类别</el-col>
                    <el-col :span="19" >
                        <div ref='cropList' class='cropListContent' :class='className'>
                            <div :class='selectCropList==-1?"active":""' @click='onSelectCropList(-1, "")' class='typeName'>全部</div>
                        <div v-for='(itm, idx) in machines' :key='idx+"c"' :class='selectCropList==idx?"active":""'  class='typeName' @click='onSelectCropList(idx, itm.id)' >{{itm.name}}</div>
                        </div>
                    </el-col>
                    <el-col :span="2" v-if='isShowMore'>
                        <div class='tip' @click='onTip'>{{tip}}<i :class="className?'el-icon-arrow-up':'el-icon-arrow-down'"></i></div>
                    </el-col>
                </el-row>
                <!-- <el-row class='publiceCommon'>
                    <el-col :span="3" class='title'>机具类别</el-col>
                    <el-col :span="21">
                        <div :class='selectBigType==-1?"active":""' @click='onSelectBigType(-1, "")' class='typeName'>全部</div>
                        <div v-for='(itm, idx) in bigTypeList' :key='idx+"c"' :class='selectBigType==idx?"active":""'  class='typeName' @click='onSelectBigType(idx, itm.id)' >{{itm.name}}</div>
                    </el-col>
                </el-row> -->
                <el-row class='publiceCommon' v-if='machinesSmall.length>0'>
                    <el-col :span="2" class='title'>机具小类</el-col>
                    <el-col :span="22">
                         <div class='typeName'  @click='onSelectCon(-1, "")' :class='selectGermplasmMenu==-1?"active":""'>全部</div>
                         <div v-for='(itm, idx) in machinesSmall' :key='idx+"i"' :class='selectGermplasmMenu==idx?"active":""'  class='typeName' @click='onSelectCon(idx, itm.id)' >{{itm.name}}</div>
                    </el-col>
                </el-row>
                <!-- <div class="flex publiceCommon" v-if='machinesSmall.length>0'>
                    <div class='title'>机具小类</div>
                    <div class = 'flex'>
                        <div class='typeName'  @click='onSelectCon(-1, "")' :class='selectGermplasmMenu==-1?"active":""'>全部</div>
                         <div v-for='(itm, idx) in machinesSmall' :key='idx+"i"' :class='selectGermplasmMenu==idx?"active":""'  class='typeName' @click='onSelectCon(idx, itm.id)' >{{itm.name}}</div>
                    </div>
                   
                </div> -->
            </div>
            <div class="clearfix">
                <div class='leftContent fl' v-if='machinesList.total > 0'>
                    <div class='result'>共有 <span>{{machinesList.total}}</span> 个相关结果</div>
                    <!-- <div v-for='(item, index) in machinesList.rows' :key='index+ "g"' class='germplasmMenuList'> -->
                        <router-link tag="div" :to="{ path: '/machineSubsidyDetail', query:{id:item.id} }"  v-for='(item, index) in machinesList.rows' :key='index+ "g"' class='germplasmMenuList'>
                            <div class='scientificName'>{{item.subFile}} <span :class='item.closeFlag===1?"active":""'>补贴¥ {{item.countryMoney}}</span></div>
                            <div class='field clearfix'>
                                <span>机具小类：{{item.typeSmall}}</span>
                                <span>机具大类：{{item.typeBig}}</span>
                                <span>产品名称：{{item.name}}</span>
                                <span>机具品目：{{item.typeItem}}</span>
                                <span>规格型号：{{item.model}}</span>
                                <p>生产企业：{{item.enterpriseName}}</p>
                                <p>配置参数：{{item.configurationParameterReport}}</p>
                            </div>
                            <img v-if='item.closeFlag' class='close' :src="require('@/assets/images/close.png')" alt="">
                        </router-link>
                    <!-- </div> -->
                    <pagination v-if='page.total > param.pageSize' @pageChange="pageChange" :total='page.total' :currentPage='page.currentPage' :pageSize='page.pageSize'></pagination>
                </div>
                <div v-else class='fl nodata'>
                    <no-data></no-data>
                </div>
                <div class='fl rightContent'>
                    <hot-demand></hot-demand>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import pagination from '@/component/pagination/index.vue';
import noData from '@/component/noData/index.vue';
import hotDemand from '@/component/hotDemand/index.vue';

export default {
    components: {
        nfBreadcrumb,
        pagination,
        noData,
        hotDemand
    },
    data() {
        return {
             breadList: [{
                name: '数据查询'
            }, {
                name: '农机补贴数据 '
            }],
            selectGermplasmMenu: -1,
            selectCropList: -1,
            page: {
                total: 0,
                currentPage: 1,
                pageSize: 8
            },
            param: {
                pageNum: 1,
                pageSize: 8,
                typeBig: '',
                typeSmall: ''
            },
            tip: '收起',
            className: 'more',
            machines: [],
            machinesSmall: [],
            machinesList: '',
            height: '',
            isShowMore: false,
            loading: true
        };
    },
    mounted() {
        this.getBigType();
        this.getMachineList();
    },
    watch: {
        height: {
            handler(val) {
                if (val > 68) {
                    this.isShowMore = true;
                } else {
                    this.isShowMore = false;
                }
            },
            deep: true
        }
    },
    methods: {
        async onSelectCon(idx, id) {
            this.selectGermplasmMenu = idx;
            this.selectCropList = 0;
            this.page.currentPage = 1;
            this.param.typeSmall = id;
            await this.getMachineList();
        },
        onSelectCropList(idx, id) {
            this.selectCropList = idx;
            this.page.currentPage = 1;
            this.param.typeBig = id;
            this.machinesSmall = this.machines[idx].children;
            if (this.machinesSmall.length > 0) {
                this.typeSmall = this.machinesSmall[0].id;
            }
            this.getMachineList();
        },
        onTip() {
            if (this.className) {
                this.className = '';
                this.tip = '展开';
            } else {
                this.className = 'more';
                this.tip = '收起';
            }
            
        },
        pageChange (item) {
            this.page.currentPage = item.page_index;
            this.page.pageSize = item.page_limit;
            this.getMachineList(); //改变页码，重新渲染页面
        },
         // 筛选请求
        async getBigType() {
            const api = this.$fetchApi.subsidyQueryScreening.api;
            const data = await this.$fetchData.fetchGet({}, api, 'json');
            if (data.code === '200' && data.result.length > 0) {
                this.machines = data.result;
                this.machinesSmall = data.result[0].children;
            }
            this.$nextTick(() => {
                this.height =  this.$refs.cropList.offsetHeight;
            });
        },
        async getMachineList() {
            this.loading = true;
            const api = this.$fetchApi.subsidyEnquiryHomepage.api;
            const data = await this.$fetchData.fetchPost(this.param, api, 'json');
            this.loading = false;
            if (data.code === '200') {
                this.machinesList = data.result;
            }
        }
    }
};
</script>
<style lang="less" scoped>
    @import '../../assets/css/selectCondition';
    .leftContent{
        .field{
            font-size:14px;
            color: #333;
            span{
                width: 50%;
                display: inline-block;
                margin-bottom:8px;
            }
            p{
                width:100%;
                margin-bottom:8px;
            }
        }
        .germplasmMenuList{
            position: relative;
            .close{
                position: absolute;
                right:0;
                top:0;
                width:74px;
                height:74px;
            }
        }
        
        .scientificName{
            span{
                height: 24px;
                background-color: #ff5454;
                border-radius: 4px;
                display: inline-block;
                text-align: center;
                line-height: 24px;
                color:#fff;
                font-size: 12px;
                padding: 0 5px;
                margin-left:14px;
            }
            .active{
               opacity: 0.5;
            }
        }
    }
</style>
